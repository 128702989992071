<template>
  <div class="vz-checkbox" :class="checkboxClass" @click="onChange">
    <div class="vz-checkbox-wrapper">
      <input
        :disabled="disabled"
        :checked="checked"
        type="checkbox"
        class="vz-checkbox-control"
      />
    </div>
    <slot name="label" >
      <span v-if="hasLabel" class="vz-checkbox-label">{{ label }}</span>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { KeyValueObject } from '~/types/types'

const emit = defineEmits(['update:modelValue', 'change'])

const props = defineProps({
  disabled: {
    type: Boolean,
    default: undefined
  },
  readonly: {
    type: Boolean,
    default: undefined
  },
  label: {
    type: String,
    default: ''
  },
  variant: {
    type: String,
    default: ''
  },
  small: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: Boolean,
    default: false
  },
  manual: {
    type: Boolean,
    default: false
  }
})
const checked = ref(props.modelValue)
const checkboxClass = computed((): KeyValueObject => {
  return {
    [props.variant]: props.variant,
    small: props.small,
    disabled: props.disabled,
    checked: checked.value
  }
})

const hasLabel = computed((): boolean => {
  return !!props.label
})

const onChange = (): void => {
  if (props.disabled) {
    return;
  }

  if (!props.manual) {
    checked.value = !checked.value
  }
  if (!props.disabled && !props.readonly) {
    emit('update:modelValue', checked.value)
    emit('change', checked.value)
  }
}

watch(() => props.modelValue,
  () => {
    checked.value = props.modelValue
  }
)
</script>
